import * as React from 'react';
import Story from 'styleguide/components/Story/Story';

import img from 'assets/images/stories/make-something-spark.jpg';

const DescriptionBlock = () => (
  <div>
    <p>Stand out. Connect. And stay remembered.</p>
    Grab attention, build relationships, and make deep impressions with products and services designed to
    enhance your brand.
  </div>
);

const StorySpark = () => (
  <Story
    intro="Make Something"
    title="Spark"
    description={<DescriptionBlock />}
    imageUrlMobile={img}
    imageUrlDesktop={img}
    direction="left"
    imageTitle="Make Something Spark"
    imageAlt="Make something spark"
  />
);

export default StorySpark;
